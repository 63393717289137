<template>
  <DialogContainer
    :title="config.title"
    icon="icon-appointment-on text-purple"
    contentIconBackground="bg-purple-20"
    @closeDialog="closeModal"
  >
    <template v-slot:content>
      <div class="body-big" v-text="config.description" />
      <Button
        class="margin-top"
        v-for="button in buttons"
        :key="button.key"
        :text="button.text"
        :type="button.type"
        :color="button.color"
        @clicked="hdlClickScheduleAppointmentOption(button.key)"
      />
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, Button } from '@seliaco/red-panda'

export default {
  name: 'ConfirmAppointmentModal',
  components: {
    DialogContainer,
    Button
  },
  props: {
    scheduleAppointment: Function
  },
  data () {
    const config = {
      title:
        this.$translations['appointment-pages']['confirm-appoinment'].title,
      description:
        this.$translations['appointment-pages']['confirm-appoinment']
          .description
    }

    const buttons = [
      {
        key: 'scheduleAppointment',
        text: this.$translations['appointment-pages'].buttons[
          'schedule-appointment'
        ],
        type: 'block'
      },
      {
        key: 'close',
        text: this.$translations['appointment-pages'].buttons.cancel,
        type: 'outline'
      }
    ]

    return {
      config,
      buttons
    }
  },
  methods: {
    hdlClickScheduleAppointmentOption (key) {
      if (key === 'scheduleAppointment') {
        this.scheduleAppointment()
      }

      this.closeModal()
    },
    closeModal () {
      this.$emit('close')
    }
  }
}
</script>
