<template>
  <div>
    <template
      v-if="!loading && (appointment || (specialist && services)) && isPublic"
    >
      <!-- service form -->
      <SelectService
        v-if="currentStep === formType.service"
        :specialist="specialist"
        :services="services"
        :is-editing="isEditing"
        :discount-percent="discountPercent"
        :form-general="form"
        @action="handlerStep"
        @redirect="setStep($event)"
      />

      <!-- date form -->
      <AddHoursDate
        v-if="currentStep === formType.date"
        :specialist="specialist"
        :service="form.service"
        :virtual="form.virtual"
        :is-editing="isEditing"
        :is-reschedule="isReschedule"
        :is-second-appointment="isSecondAppointment"
        @back="setStep($event, true)"
        @action="handlerStep"
        @openModal="showSpecialistModal"
        @redirect="setStep($event, true)"
      />

      <!-- reason from -->
      <AddReason
        v-if="currentStep === formType.reason"
        :is-reschedule="isReschedule"
        :is-editing="isEditing"
        :specialist="specialist"
        :appointment="appointment"
        :form-data="form"
        :reason-required="reasonRequired"
        :virtual="form.virtual"
        @openModal="showConfirmAppointmnetModal"
        @back="setStep($event, true)"
        @submit="reserveAppointment(true, $event)"
      />
    </template>

    <ChangeSpecialistModal
      v-if="openModal"
      @close="showSpecialistModal(false)"
    />

    <ConfirmAppointmentModal
      v-if="openModalConfirm"
      :schedule-appointment="scheduleAppointment"
      @close="showConfirmAppointmnetModal(false)"
    />

    <DataZeroPage v-if="specialist && !isPublic" />
  </div>
</template>

<script>
import {
  Appointments,
  Promos,
  Specialists,
  SpecialistsServices
} from '@seliaco/red-panda'
import ChangeSpecialistModal from '../../modal/ChangeSpecialistModal.vue'
import ConfirmAppointmentModal from '../../modal/ConfirmAppointmentModal.vue'
import DataZeroPage from '@/views/appointments/components/DataZeroPage'

import SelectService from '../SelectService.vue'
import AddHoursDate from './AddHoursDate.vue'
import AddReason from '../AddReason.vue'

import { BookingAndAppointmentDetailEvent } from '@/types/events'

import { formTypes } from '../../configuration/configuration-schedule'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleV2',
  components: {
    DataZeroPage,
    SelectService,
    AddHoursDate,
    AddReason,
    ChangeSpecialistModal,
    ConfirmAppointmentModal
  },
  data () {
    return {
      reasonRequired: false,
      formType: formTypes,
      specialistId: this.$route.query.specialistId,
      appointmentId: this.$route.query.appointmentId,
      appointment: null,
      specialist: null,
      services: [],
      packages: null,
      discountPercent: null,
      loaders: {
        services: false,
        packages: false,
        specialist: false,
        appointment: false
      },
      form: {
        virtual: false,
        service: null,
        starts_at: null,
        reason: null
      },
      discount: null,
      isReschedule: false,
      isEditing: false,
      isSecondAppointment: false,
      openModal: false,
      currentStep: formTypes.service,
      openModalConfirm: false,
      appointmentReserve: null,
      loading: false,
      startTime: null
    }
  },
  mounted () {
    this.startTime = Date.now()
    this.validateRoute()

    this.specialistId = this.$route.query.specialistId
    this.appointmentId = this.$route.query.appointmentId

    if (!this.specialistId && !this.appointmentId) {
      this.$router.go(-1)
    }

    this.handlerExitSchedule()

    if (this.appointmentId) {
      if (!this.$route.query.noReschedule) {
        this.isReschedule = true
      }

      this.currentStep = formTypes.date
      this.getAppointment()
    } else {
      Promise.all([
        this.getSpecialist(),
        this.getServices(),
        this.getPackages()
      ])
    }

    if (this.form?.service?.id && !this.form?.starts_at) {
      this.setStep(formTypes.date)
    } else if (this.form.starts_at && this.$route.query.noReschedule) {
      this.setStep(formTypes.reason)
    } else {
      this.setStep()
    }
  },
  destroyed () {
    this.$loader.handleRemoveLoader()
  },
  methods: {
    /**
     * Requests
     */
    async getAppointment () {
      this.loaders.appointment = true
      const lang = this.$store.getters.language.lang

      const appointments = await Appointments.read({
        columns: `
          id,
          starts_at,
          service:specialist_service (
            id,
            service_type (
              name:name_${lang},
              description:description_${lang},
              duration,
              icon_name,
              type
            )
          ),
          specialist (
            id,
            is_public,
            speciality,
            type (
              code
            ),
            user (
              profile_picture,
              first_name,
              last_name,
              address(
                country,
                city,
                full_address
              )
            )
          ),
          virtual
        `,
        eq: {
          id: this.appointmentId
        },
        orderBy: {
          column: 'starts_at',
          params: {
            asspecialistIdcending: false
          }
        }
      }).catch(() => this.showErrorToast())

      if (!appointments.data[0]) {
        this.showErrorToast()
      }

      this.appointment = appointments.data[0]
      this.specialist = this.appointment.specialist
      this.form.virtual = this.appointment.virtual
      this.form.starts_at = this.appointment.starts_at

      const services = await SpecialistsServices.getSpecialistServices(
        this.appointment.specialist.id
      ).catch(() => this.showErrorToast())

      this.form.service = services.find(
        (o) => o.service.specialist_service_id === this.appointment.service.id
      )

      this.services = [this.form.service]

      if (this.form.starts_at && this.$route.query.noReschedule) {
        this.currentStep = formTypes.reason

        this.appointmentReserve = {
          id: this.appointment.id,
          patient_name: `${this.user.first_name} ${this.user.last_name}`,
          specialist_name: `${this.specialist.user.first_name} ${this.specialist.user.last_name}`,
          starts_at: this.appointment.starts_at,
          service_name: this.appointment.service.service_type.name,
          status: 'RESERVED',
          created_at: this.appointment.created_at
        }
      } else {
        this.currentStep = formTypes.date
      }
      this.loaders.appointment = false
    },
    async getSpecialist () {
      this.loaders.specialist = true

      await Specialists.getBySpecialistId(this.specialistId)
        .then((response) => {
          this.specialist = response
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error.specialist
          })

          this.$router.go(-1)
        })
        .finally(() => (this.loaders.specialist = false))
    },
    async getPackages () {
      this.loaders.packages = true

      await Promos.Packages.getPackageBySpecialistId(this.specialistId)
        .then((response) => {
          this.packages = response
          this.packageDiscountMax()
        })
        .catch(() => null)
        .finally(() => (this.loaders.packages = false))
    },
    async getServices () {
      this.loaders.services = true

      await SpecialistsServices.getSpecialistServices(this.specialistId)
        .then((response) => {
          if (!response) {
            return
          }

          this.setDefaultService(response)
          this.services = response

          // merge service
          if (this.form.service?.id) {
            const service = this.services.find(
              (o) => o.service.id === this.form.service.id
            )

            if (service) {
              this.form.service = service
            }
          }
        })
        .catch(() => null)
        .finally(() => {
          this.loaders.services = false
        })
    },
    /**
     * Methods
     */
    setDefaultService (products) {
      const defaultProduct = products.find((product) => product.is_default)

      if (defaultProduct) {
        this.reasonRequired = defaultProduct.first_session
        this.isSecondAppointment = true

        this.form.service = { ...defaultProduct }
        this.form.virtual = true
        this.currentStep = formTypes.date
      }

      this.validateRoute()
    },
    packageDiscountMax () {
      if (!this.packages.length) {
        return
      }

      let discount = 0

      this.packages.forEach((o) => {
        if (o.discount > discount) {
          discount = o.discount
        }
      })

      this.discountPercent = discount
    },
    showErrorToast (text = this.$translations.error.default) {
      this.$toast({
        severity: 'error',
        text
      })
    },
    async handlerStep (value) {
      let type = ''

      switch (this.currentStep) {
        case formTypes.service:
          this.form.virtual = value.virtual
          this.form.service = value.service
          this.reasonRequired = value.service?.first_session

          type = formTypes.date
          break

        case formTypes.date:
          this.form.starts_at = value
          if (!this.isReschedule) {
            if (!this.interval) {
              this.setTimer()
            }
            await this.reserveAppointment()
          }
          type = formTypes.reason
          break
      }

      this.setStep(type)
    },
    setStep (step = formTypes.service, isEditing = false) {
      // handle back from specialist profile
       if (this.$route.query.origin === 'schedule-express' && step === formTypes.date) {
         this.$router.replace(this.$route.query.back)
       }

      this.isEditing = isEditing

      const content = document.getElementsByClassName('page-container-content')
      if (content.length) {
        content[0].classList.remove('fade-in-left')
        content[0].classList.add('fade-out-right')
      }

      setTimeout(() => {
        this.currentStep = step
      }, 500)
    },
    showSpecialistModal (show = true) {
      this.openModal = show
    },
    showConfirmAppointmnetModal (show = true) {
      this.openModalConfirm = show
    },
    validateRoute () {
      if (this.$route.query.origin === 'shared-account' && !this.member?.id) {
        if (this.$route.query.back) {
          this.$loader.handleRemoveLoader()
          this.$router.replace(this.$route.query.back)
        }
      }

      if (this.$route.query.form) {
        const data = JSON.parse(this.$route.query.form)
        if (!!data.virtual && data.service) {
          this.form.virtual = data.virtual
          this.form.service = data.service
          this.form.starts_at = data?.start_at
        }
      }
    },
    async reserveAppointment (reasonStep = false, reason) {
      let body = {
        specialist_service:
          this.form.service?.service?.specialist_service_id ||
          this.form.service ||
          null,
        specialist: this.specialistId,
        virtual: this.form.virtual,
        starts_at: this.form.starts_at,
        patient: this.user.id
      }

      if (reasonStep || this.appointmentReserve?.id) {
        body = {
          ...body,
          starts_at: this.form.starts_at,
          id: this.appointmentReserve.id,
          reason
        }
      }

      const appointment = await Appointments.reserveAppointment(body).catch(
        (error) => {
          this.$router
            .replace({
              name: 'SpecialistDetail',
              params: {
                id: this.specialistId
              }
            })
            .then(() => {
              this.$toast({
                severity: 'error',
                text: error.message
              })
            })
        }
      )

      this.appointmentReserve = appointment

      if (reasonStep) {
        BookingAndAppointmentDetailEvent.ScheduleAppointmentSuccessful({
          user: this.user,
          bookappointment: {
            appointment_id: this.appointmentReserve.id,
            patient_name: this.appointmentReserve.patient_name,
            specialist_name: this.appointmentReserve.specialist_name,
            starts_at: this.appointmentReserve.starts_at,
            day: this.$moment(this.appointmentReserve.starts_at).format(
              'DD/MM/YYYY'
            ),
            time: this.$moment(this.appointmentReserve.starts_at).format(
              'HH:mm'
            ),
            service_name: this.appointmentReserve.service_name,
            status: this.appointmentReserve.status,
            created_at: this.appointmentReserve.created_at
          }
        })

        await this.$router.push({
          name: 'CheckOut',
          params: {
            id: this.appointmentReserve.id,
            type: 'APPOINTMENT'
          },
          query: {
            specialistId: this.specialistId,
            origin:
              this.$route.query.origin === 'specialist-card'
                ? this.$route.query.origin
                : null
          }
        })
      }
    },
    scheduleAppointment () {},
    setTimer () {
      this.$store.dispatch('timer/set', { moment: this.$moment })
    },
    /**
     * Listeners
     */
    handlerExitSchedule () {
      window.onpopstate = () => {
        history.pushState(null, document.title, location.href)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      member: 'sharedAccount/member',
      interval: 'timer/interval'
    }),
    isPublic () {
      return this.appointmentId || this.specialist.is_public
    }
  },
  watch: {
    loaders: {
      handler ({ services, packages, specialist, appointment }) {
        if (!this.loading) {
          this.loading = services || packages || specialist || appointment
        } else {
          const value = services || packages || specialist || appointment

          const elapsedTime = Date.now() - this.startTime

          if (elapsedTime < 3000) {
            const remainingTime = 3000 - elapsedTime
            setTimeout(() => {
              this.loading = this.loading ? value : false
            }, remainingTime)
          } else {
            this.loading = value
          }
        }
      },
      immediate: true,
      deep: true
    },
    loading: {
      handler (val) {
        if (val) {
          this.$loader.handleShowLoader({
            lang: this.$store.getters.language.lang,
            type: 'degrade'
          })
        } else {
          this.$loader.handleRemoveLoader()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.skeleton-wrapper
  width: 100vw
  height: 100vh
  margin-top: env(safe-area-inset-top)
  margin-bottom: env(safe-area-inset-bottom)
</style>
