<template>
  <PageContainer
    :title="$translations.appointments.schedule.title"
    :back="actions.back"
    :action="close"
    :has-shy-header="true"
    :ellipsis="true"
  >
    <template v-slot:content>
      <DataZeroSimple
        :text="$translations['data-zero']['specialist-unavailable']"
        :image="image"
      />
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer } from '@seliaco/red-panda'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
export default {
  name: 'DataZeroPage',
  components: { DataZeroSimple, PageContainer },
  data () {
    return {
      actions: {
        back: {
          callback: () => this.$router.go(-1)
        }
      },
      close: {
        callback: () => this.$router.go(-1),
        icon: 'icon-close'
      }
    }
  },
  computed: {
    image () {
      return require('@/assets/icons/no_availability.svg')
    }
  }
}
</script>
