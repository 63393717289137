<template>
  <div v-if="address && showAddress">
    <div v-if="showFull" class="heading-small margin-bottom">
      {{ $translations['specialist-page'].address.location }}
    </div>

    <template>
      <div class="title">
        {{ $translations['specialist-page'].address['consulting-room'] }} {{ firstName }}

        <OpenInGoogleMaps
          v-if="address && address.place_id || address.full_address"
          :language="$store.getters.language.lang"
          :place_id="address.place_id"
          :full-address="address.full_address"
        />
      </div>

      <div class="container">
        <span class="icon icon-pin-on"></span>
        <span class="content">{{ fullAddress }}</span>
      </div>

    </template>
  </div>
</template>

<script>
import { OpenInGoogleMaps } from '@seliaco/red-panda'

export default {
  name: 'Address',
  components: {
    OpenInGoogleMaps
  },
  props: {
    address: Object,
    showFull: Boolean,
    showAddress: Boolean,
    firstName: String
  },
  computed: {
    fullAddress () {
      return this.address?.full_address || '---'
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: row
  align-items: center

.title
  display: grid
  grid-template-columns: 1fr max-content
  font-size: 14px
  color: var(--color-dark-gray)
  padding: 4px 0 8px 0

.content
  font-size: 12px
  color: var(--color-gray-70)

.icon
  color: var(--color-gray-55)
  font-size: 24px
  padding-right: 8px
</style>
