<template>
  <PageContainer
    :title="$translations['appointment-pages']['select-service'].title"
    :submit="submit"
    :back="back"
    :has-shy-header="true"
  >
    <template v-slot:content>
      <ServicesList
        :specialist="specialist"
        :services="services"
        :discountPercent="discountPercent"
        :is-editing="isEditing"
        :default-service="form"
        @valid="updateSubmit"
        @action="handlerForm"
      />
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer } from '@seliaco/red-panda'
import ServicesList from '@/components/services/ServicesList'
import ScheduleMixin from '@/mixins/schedule-mixin'
import NavigationHandlerMixin from '@/mixins/navigation-handler-mixin'

export default {
  name: 'ScheduleService',
  props: {
    specialist: Object,
    services: Array,
    isEditing: Boolean,
    discountPercent: {
      type: Number,
      default: 12
    },
    formGeneral: Object
  },
  components: {
    PageContainer,
    ServicesList
  },

  mixins: [ScheduleMixin, NavigationHandlerMixin],
  data () {
    return {
      showAddress: false,
      form: {
        virtual: true,
        service: null
      },
      submit: {
        text: this.$translations['appointment-pages'].buttons.continue,
        loading: false,
        disabled: true
      },
      back: {
        callback: () => {
          if (this.$route.query.back) {
            this.$router.replace({
              path: this.$route.query.back,
              query: this.$route.query
            })
          } else {
            this.$router.go(-1)
          }
        }
      }
    }
  },
  mounted () {
    if (this.specialist.type.code === 'COUNSELING') {
      this.$router
        .push({ name: 'RecommendedCounselling', query: { next: 'Home' } })
        .then(() => null)
    }
    if (this.isEditing) {
      this.submit = {
        text: this.$translations['appointment-pages'].buttons.continue,
        loading: false,
        disabled: true,
        callback: () => {
          this.$emit('action', this.form)
        }
      }

      if (this.formGeneral) {
        this.submit.disabled = false
      }
    }
  },
  methods: {
    handleAvailabilityFormChanged (isValid) {
      this.isEditing = !isValid
    },
    updateSubmit (value) {
      this.submit.disabled = value
    },
    handlerForm (form) {
      if (this.isEditing) {
        this.form = { ...form }
      } else {
        this.$emit('action', form)
      }
    },
    /**
     * Mixins overrides
     */
    handleBackButton () {
      this.back.callback()
    }
  },
  watch: {
    formGeneral: {
      handler (val) {
        if (val) {
          this.form = {
            virtual: val.virtual,
            service: val.service
          }
        }
      },
      immediate: true
    }
  }
}
</script>
