<template>
  <div class="service-card-wrapper" v-if="optionsData">
    <ServiceCard
      v-for="(option, index) in optionsData"
      :key="index"
      :service="option"
      :icon-name="option.service.icon_name"
      :active="Boolean(selected && selected.id === option.id)"
      :specialist-public="specialistPublic"
      @changed="handleSelectService"
    />
  </div>
</template>

<script>
import ServiceCard from './ServiceCard'

export default {
  name: 'ServiceSelector',
  components: {
    ServiceCard
  },
  props: {
    optionsData: Array,
    specialistPublic: Boolean,
    service: Object
  },
  data () {
    return {
      selected: null
    }
  },
  methods: {
    handleSelectService (service) {
      if (this.selected && this.selected.id === service.id) {
        return
      }

      this.selected = service
      this.$emit('changed', service)
    }
  },
  watch: {
    service: {
      handler (val) {
        if (val) {
          this.selected = val
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.service-card-wrapper
  display: flex
  flex-direction: column
  gap: 8px
</style>
