<template>
  <div>
    <ScheduleV2 />
  </div>
</template>

<script>
import ScheduleV2 from './pages/v2/Schedule'

export default {
  name: 'ScheduleWrapper',
  components: { ScheduleV2 }
}
</script>

<style lang="sass" scoped>
.skeleton-wrapper
  width: 100vw
  height: 100vh
  margin-top: env(safe-area-inset-top)
  margin-bottom: env(safe-area-inset-bottom)
</style>
