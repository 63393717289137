<template>
  <div
    v-selectable-container
    class="service-card"
    :class="{
      disable: !specialistPublic,
      active: active
    }"
    @click="handleSelectService"
  >
    <!-- left side component -->
    <div class="left-side" :style="setBackground" />

    <!-- service info -->
    <div class="service-info-container">
      <!-- name -->
      <div
        class="service-name"
        v-text="service.service ? service.service.name : option.name"
      />
      <!-- description -->
      <div
        class="service-description"
        v-text="
          service.service ? service.service.description : service.description
        "
      />

      <!-- when have benefit or discount -->
      <div
        class="service-benefit-container"
        v-if="
          discount &&
          (discount.slug === 'BENEFIT' || discount.slug === 'PROMOTION') &&
          allowedByRole([roleType.USER, roleType.USER_B2B])
        "
      >
        <div>
          <Chip
            size="small"
            :color="discount && discount.slug === 'BENEFIT' ? 'blue' : 'purple'"
            :text="chipText"
          />
        </div>

        <div class="service-price">
          <div v-text="subtotal" />
        </div>
      </div>

      <!-- price and duration -->
      <div class="service-price-and-duration">
        <!-- duration -->
        <div class="chip" :class="{ active: active }">
          <div
            class="icon icon-timer-off"
            :class="{ active: active }"
            v-if="allowedByRole([roleType.USER, roleType.USER_B2B])"
          />
          <div class="text">{{ service.service.duration }} min.</div>
        </div>

        <!-- price -->
        <div class="chip" :class="{ active: active }">
          <!-- promotions -->
          <template v-if="discount">
            <!-- has package -->
            <template v-if="discount.slug === 'PACKAGE'">
              <UsagesCoins
                :option="service"
                :icon-name="iconName"
                :max="service.pricing.discounts[0].meta.max_uses"
                :remaining="service.pricing.discounts[0].meta.remaining_uses"
              />
            </template>

            <!-- any other promotion -->
            <template
              v-else-if="
                discount.slug === 'BENEFIT' || discount.slug === 'PROMOTION'
              "
            >
              <div class="icon icon-money-off" :class="{ active: active }" />

              <div class="text">
                <template v-if="service.pricing.total === 0">
                  {{ $translations.general['free-session'] }}
                </template>

                <template v-else>
                  <div v-text="price" />
                </template>
              </div>
            </template>
          </template>

          <!-- default -->
          <template v-else>
            <div class="icon icon-money-off" :class="{ active: active }" />
            <div class="text" v-text="price" />
          </template>
        </div>
      </div>
    </div>

    <!-- selection icon -->
    <div
      @click="handleSelectService"
      v-if="specialistPublic"
      class="selection-icon radio-icon"
      :class="{ active: active }"
    />
  </div>
</template>

<script>
import UsagesCoins from '@/components/UsagesCoins'
import {
  Chip,
  selectableContainer,
  CurrencyFilter as currency,
  ServiceIcons,
  RoleMixin
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'ServiceCard',
  components: {
    UsagesCoins,
    Chip
  },
  directives: {
    selectableContainer
  },
  mixins: [RoleMixin],
  props: {
    service: Object,
    active: Boolean,
    iconName: String,
    specialistPublic: Boolean
  },
  data () {
    return {
      styleConfig: ServiceIcons
    }
  },
  mounted () {
    if (this.service.is_default) {
      this.$emit('changed', this.service)
    }
  },
  methods: {
    handleSelectService () {
      this.$emit('changed', this.service)
    }
  },
  computed: {
    ...mapGetters({
      role: 'auth/role'
    }),
    setBackground () {
      const background =
        this.styleConfig[
          this.service.service
            ? this.service.service.icon_name
            : this.service.icon_name
        ].background

      return `background: ${background}`
    },
    discount () {
      if (!this.service?.pricing?.discounts[0]) {
        return null
      }

      return this.service.pricing.discounts[0]
    },
    price () {
      return currency(
        this.service.pricing.total / 100,
        this.service.currency.toUpperCase()
      )
    },
    subtotal () {
      return currency(
        this.service.pricing.subtotal / 100,
        this.service.currency.toUpperCase()
      )
    },
    chipText () {
      const discount = this.service.pricing.discounts[0]

      if (discount.slug === 'PROMOTION') {
        return discount.name
      } else if (discount.slug === 'BENEFIT') {
        if (this.service.pricing.total === 0) {
          return this.$translations.general['free-session']
        } else {
          const percentage = discount.meta.percentage_value
          return `${percentage}% ${this.$translations.promos.discount}`
        }
      }

      return ''
    }
  }
}
</script>

<style lang="sass" scoped>
.service-card
  display: flex
  position: relative
  overflow: hidden
  cursor: pointer
  padding: 16px 16px 16px 32px
  border-radius: 16px
  gap: 16px
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  transition-duration: 300ms
  background: var(--color-white)
  box-shadow: 0px 5px 8px rgba(141, 145, 160, 0.1)
  border: 2px solid var(--color-gray-10)

  &.active
    color: var(--color-purple)
    border: 2px solid var(--color-purple-10)
    background: var(--color-purple-50)
    box-shadow: 0px 5px 20px rgba(131, 80, 230, 0.2)
  &.disable
    background: var(--gray-5)
    pointer-events: none

  &:hover:not(.active)
    border: 2px solid var(--color-purple-10)

.left-side
  width: 16px
  position: absolute
  height: 100%
  left: 0
  top: 0

.service-info-container
  width: calc(100% - 36px)
  flex-grow: 1

  .service-name
    font-size: var(--tiny)
    font-weight: 600
  .service-description
    font-size: var(--sm)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    color: var(--color-gray-70)
  .service-benefit-container
    display: flex
    gap: 16px
    margin-top: 8px
    .service-price
      text-align: right
      padding: 2px 10px
      color: var(--color-gray-55)
      font-size: var(--xs)
      text-decoration: line-through
      white-space: nowrap
      align-self: end
  .service-price-and-duration
    display: flex
    gap: 8px
    margin-top: 8px

    .chip
      display: flex
      gap: 4px
      padding: 8px 12px
      border-radius: 8px
      color: var(--color-dark-gray)
      font-weight: 600
      font-size: 14px
      white-space: nowrap
      background: var(--gray-10)
      &.active
        background: var(--purple-10)
    .text
      align-self: center
      font-size: var(--tiny)
.icon
  align-self: center
  font-size: var(--xl)
  color: var(--color-gray-55)
  &.active
    color: var(--color-purple-10)

.selection-icon
  flex: none
  align-self: center
  cursor: pointer
</style>
