<template>
  <FullScreenDialog
    :title="config.title"
    :canBeClosed="true"
    :backdropCloses="true"
    icon="icon-specialists-on"
    iconColor="purple"
    backgroundStyle="transparent"
    @close="$emit('close')"
  >
    <template v-slot:body>
      <p style="text-align: center" class="heading-mate margin-bottom-xl">
        {{ config.confirmation }}
      </p>

      <Button
        class="margin-y"
        v-for="button in buttons"
        :key="button.key"
        :text="button.text"
        :type="button.type"
        :color="button.color"
        @clicked="hdlClickFindSpecialistOption(button.key)"
      />
    </template>
  </FullScreenDialog>
</template>

<script>
import { FullScreenDialog, Button } from '@seliaco/red-panda'
import { BookingAndAppointmentDetailEvent } from '@/types/events'
import NavigateMatchingResetMixin from '@/mixins/navigate-matching-reset-mixin'
export default {
  name: 'ChangeSpecialistModal',
  mixins: [NavigateMatchingResetMixin],
  data () {
    const config = {
      confirmation:
        this.$translations['appointment-pages']['change-specialist']
          .confirmation,
      title: this.$translations['appointment-pages']['change-specialist'].title
    }

    const buttons = [
      {
        key: 'orientation',
        text: this.$translations['appointment-pages'].buttons[
          'answer-questionnaire'
        ],
        type: 'block'
      },
      {
        key: 'specialist',
        text: this.$translations['appointment-pages'].buttons[
          'view-all-specialists'
        ],
        type: 'outline'
      },
      {
        key: 'close',
        text: this.$translations['appointment-pages'].buttons.close,
        type: 'outline',
        color: 'gray'
      }
    ]
    return {
      config,
      buttons
    }
  },
  methods: {
    hdlClickFindSpecialistOption (key) {
      if (key === 'orientation') {
        this.orientation()
      }

      if (key === 'specialist') {
        this.specialist()
      }

      this.closeModal()
    },
    closeModal () {
      this.$emit('close')
    },
    orientation () {
      this.handleMatchingNavigation()
      this.$segment.track(BookingAndAppointmentDetailEvent.select_beginnow)
    },
    specialist () {
      this.$router.push('/search?on_focus=true')
    }
  },
  components: {
    Button,
    FullScreenDialog
  }
}
</script>
