<template>
  <PageContainer
    :title="translations.title"
    :submit="submit"
    :back="back"
    :hasShyHeader="true"
  >
    <template v-slot:content>
      <div class="segment-content schedule-description">
        <InfoData
          icon="icon-briefcase-off"
          :text="serviceContent.serviceName"
        />

        <InfoData
          :icon="serviceContent.modalityIcon"
          :text="serviceContent.modality"
        />

        <InfoData icon="icon-clock-off" :text="serviceContent.duration" />
      </div>

      <div class="segment-content">
        <AppointmentDateSelector
          class="padding-y"
          :specialist-id="specialist.id"
          :service-id="service.id"
          :service-modality="virtual ? 'VIRTUAL' : 'FACE_TO_FACE'"
          @changed="setDate"
        />
      </div>

      <div class="segment">
        <div class="segment-content text-center">
          <div class="body" v-text="translations['no-availability']" />
          <Button
            type="link"
            :text="translations['no-availability-button']"
            @clicked="goToTypeform"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Settings, Button, InfoData } from '@seliaco/red-panda'
import { Browser } from '@capacitor/browser'

import AppointmentDateSelector from '@/components/fields/v2/AppointmentDateSelector'

import ScheduleMixin from '@/mixins/schedule-mixin'
import NavigationHandlerMixin from '@/mixins/navigation-handler-mixin'
import ResponsiveMixin from '@/mixins/responsive.mixin'
import { formTypes } from '../../configuration/configuration-schedule'
import { AppointmentFlowEvent } from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleHoursDate',
  props: {
    isReschedule: Boolean,
    isEditing: Boolean,
    isSecondAppointment: Boolean,
    specialist: Object,
    service: Object,
    virtual: Boolean
  },
  components: {
    AppointmentDateSelector,
    PageContainer,
    Button,
    InfoData
  },
  mixins: [ScheduleMixin, NavigationHandlerMixin, ResponsiveMixin],
  data () {
    return {
      translations: this.$translations['appointment-pages']['add-hours-date'],
      steps: formTypes,
      form: null,
      date: null,
      itemsState: {
        service: 'disabled',
        specialists: 'disabled'
      },
      submit: {
        text: this.$translations['appointment-pages'].buttons.continue,
        loading: false,
        disabled: true
      },
      back: {
        callback: () => {
          if (this.isReschedule) {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.go(-1)
            }
          } else {
            this.$emit('back', formTypes.service)
          }
        }
      },
      body: null
    }
  },
  mounted () {
    this.body = {
      user: this.user,
      specialist_is_public: this.specialist.is_public,
      service_name: this.service.service.name
    }

    AppointmentFlowEvent.Appointment_View_Date_Time(this.body)

    this.submit = {
      text: this.$translations['appointment-pages'].buttons.continue,
      loading: false,
      disabled: true,
      callback: () => {
        this.submit.loading = true
        this.$emit('action', this.form)
      }
    }
  },
  methods: {
    setDate (value) {
      if (!value || (value && !value.code)) {
        this.form = null
        return
      }
      this.form = value.code
      this.submit.disabled = false
      AppointmentFlowEvent.Appointment_Select_Date({
        ...this.body,
        date: value.code
      })
    },
    goToTypeform () {
      Settings.get('SELIA_USERS_AVAILABILITY_FORM_URL')
        .then((value) => {
          const url = value.parse_value
            .replaceAll('{USER_ID}', this.user.id)
            .replaceAll('{SPECIALIST_ID}', this.specialist.id)

          const body = {
            specialist_id: this.specialist.id,
            specialist_name: `${this.specialist.user.first_name} ${this.specialist.user.last_name}`,
            service_name: this.service.service.name,
            service_price: this.service?.service.price,
            user: this.user,
            phone_number: this.user.phone,
            email: this.user.email
          }
          AppointmentFlowEvent.Appointment_Form_Click(body)
          Browser.open({ url }).catch((e) => {
            this.$toast({
              text: e.message || this.$translations.error.default,
              severity: 'error'
            })
          })
        })
        .catch((e) => {
          this.$toast({
            text: e.message,
            severity: 'error'
          })
        })
    },
    /**
     * Mixins overrides
     */
    handleBackButton () {
      this.back.callback()
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    avatar () {
      return (
        this.specialist?.user?.profile_picture ||
        require('@/assets/images/avatar.png')
      )
    },
    serviceContent () {
      const modality =
        this.$translations.appointments.virtual[this.virtual.toString()]
      const duration = `${this.service.service.duration} min`

      return {
        serviceName: this.service.service.name,
        modality,
        modalityIcon: this.virtual
          ? 'icon-video-outline-off'
          : 'icon-person-off',
        duration
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.schedule-description
  display: flex
  gap: 12px
  margin-bottom: 4px
  padding-left: 16px
  padding-top: 10px
</style>
