var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"selectable-container",rawName:"v-selectable-container"}],staticClass:"service-card",class:{
    disable: !_vm.specialistPublic,
    active: _vm.active
  },on:{"click":_vm.handleSelectService}},[_c('div',{staticClass:"left-side",style:(_vm.setBackground)}),_c('div',{staticClass:"service-info-container"},[_c('div',{staticClass:"service-name",domProps:{"textContent":_vm._s(_vm.service.service ? _vm.service.service.name : _vm.option.name)}}),_c('div',{staticClass:"service-description",domProps:{"textContent":_vm._s(
        _vm.service.service ? _vm.service.service.description : _vm.service.description
      )}}),(
        _vm.discount &&
        (_vm.discount.slug === 'BENEFIT' || _vm.discount.slug === 'PROMOTION') &&
        _vm.allowedByRole([_vm.roleType.USER, _vm.roleType.USER_B2B])
      )?_c('div',{staticClass:"service-benefit-container"},[_c('div',[_c('Chip',{attrs:{"size":"small","color":_vm.discount && _vm.discount.slug === 'BENEFIT' ? 'blue' : 'purple',"text":_vm.chipText}})],1),_c('div',{staticClass:"service-price"},[_c('div',{domProps:{"textContent":_vm._s(_vm.subtotal)}})])]):_vm._e(),_c('div',{staticClass:"service-price-and-duration"},[_c('div',{staticClass:"chip",class:{ active: _vm.active }},[(_vm.allowedByRole([_vm.roleType.USER, _vm.roleType.USER_B2B]))?_c('div',{staticClass:"icon icon-timer-off",class:{ active: _vm.active }}):_vm._e(),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.service.service.duration)+" min.")])]),_c('div',{staticClass:"chip",class:{ active: _vm.active }},[(_vm.discount)?[(_vm.discount.slug === 'PACKAGE')?[_c('UsagesCoins',{attrs:{"option":_vm.service,"icon-name":_vm.iconName,"max":_vm.service.pricing.discounts[0].meta.max_uses,"remaining":_vm.service.pricing.discounts[0].meta.remaining_uses}})]:(
              _vm.discount.slug === 'BENEFIT' || _vm.discount.slug === 'PROMOTION'
            )?[_c('div',{staticClass:"icon icon-money-off",class:{ active: _vm.active }}),_c('div',{staticClass:"text"},[(_vm.service.pricing.total === 0)?[_vm._v(" "+_vm._s(_vm.$translations.general['free-session'])+" ")]:[_c('div',{domProps:{"textContent":_vm._s(_vm.price)}})]],2)]:_vm._e()]:[_c('div',{staticClass:"icon icon-money-off",class:{ active: _vm.active }}),_c('div',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.price)}})]],2)])]),(_vm.specialistPublic)?_c('div',{staticClass:"selection-icon radio-icon",class:{ active: _vm.active },on:{"click":_vm.handleSelectService}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }