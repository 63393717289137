<template>
  <div>
    <div class="segment">
      <div class="segment-content">
        <ModalitySelector
          @changed="setModality"
          :presentialOption="hasInPersonModality"
        />
      </div>
    </div>

    <!-- specialist consultation room -->
    <div class="page-segment" v-if="showConsultationRoom">
      <div class="page-segment-content">
        <!-- address -->
        <Address
          :show-address="showAddress"
          :address="specialistPriv.user.address"
          :first-name="specialistPriv.user.first_name"
          :showFull="false"
        />
      </div>
    </div>

    <!-- services -->
    <div class="page-segment">
      <div class="page-segment-content">
        <ServiceSelector
          :service="form.service"
          @changed="setService"
          :specialist-public="specialist.is_public"
          :optionsData="getServiceOptions"
        />
      </div>
    </div>

    <!-- package banner -->
    <div
      class="segment"
      v-if="allowedByRole([roleType.USER, roleType.USER_B2B])"
    >
      <div class="segment-content">
        <PackagePromotionBanner
          v-if="Boolean(discountPercent > 0)"
          :specialist="specialistPriv"
          :discount-percent="discountPercent"
          origin="specialist-availability"
          backgroundColor="white"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Address from '@/views/specialist/components/Address'
import ModalitySelector from '@/components/fields/ModalitySelector'
import PackagePromotionBanner from '@/views/promos/packages/components/PackagePromotionBanner'
import ServiceSelector from './ServiceSelector'
import { AppointmentFlowEvent } from '@/types/events'
import { RoleMixin } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'ServicesList',
  components: {
    Address,
    ServiceSelector,
    ModalitySelector,
    PackagePromotionBanner
  },
  mixins: [RoleMixin],
  props: {
    specialist: Object,
    defaultService: Object,
    services: Array,
    discountPercent: {
      type: Number,
      default: 12
    },
    isEditing: Boolean
  },
  data () {
    return {
      showAddress: false,
      specialistPriv: null,
      form: {
        virtual: true,
        service: null
      }
    }
  },
  methods: {
    setModality (value) {
      this.form.virtual = value.code === 'ONLINE'
      this.showAddress = value.code === 'FACE_TO_FACE'
      this.valid()
    },
    setService (value) {
      this.form.service = value
      this.valid()
    },
    valid () {
      const valid = Boolean(this.form.service)

      if (this.form.service) {
        if (this.isEditing) {
          this.$emit('valid', !valid)
          this.$emit('action', this.form)
        } else {
          this.$emit('action', this.form)

          const body = {
            user: this.$store.getters['auth/user'],
            serviceType: this.form.virtual ? 'virtual' : 'face-to-face',
            service: this.form.service.service.name
          }
          AppointmentFlowEvent.Appointment_Select_Service_Type(body)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      role: 'auth/role'
    }),
    getServiceOptions () {
      if (this.form.virtual) {
        return this.services
      }
      const temp = []
      for (const item of this.services) {
        if (item.kalendme_in_person_link_id) {
          temp.push(item)
        }
      }
      return temp
    },
    hasInPersonModality () {
      for (const item of this.services) {
        if (item.kalendme_in_person_link_id) {
          return true
        }
      }

      return false
    },
    showConsultationRoom () {
      return this.specialist?.user?.address && this.showAddress
    }
  },
  watch: {
    specialist: {
      handler (val) {
        if (val) {
          this.specialistPriv = { ...val }
        }
      },
      deep: true,
      immediate: true
    },
    defaultService: {
      handler (val) {
        if (val) {
          this.form = {
            virtual: val.virtual,
            service: val.service
          }
        }
      },
      immediate: true
    }
  }
}
</script>
