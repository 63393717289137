<template>
  <PageContainer
    :title="config.title"
    :submit="submit"
    :back="back"
    :hasShyHeader="true"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <TextareaField
            v-model="reason"
            className="text-area margin-top-zero"
            :label="config.heading"
            :value="reason"
            @change="setReason"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  Appointments,
  PageContainer,
  SharedAccountAppointment
} from '@seliaco/red-panda'
import { TextareaField } from '@seliaco/sea-otter'
import ScheduleMixin from '@/mixins/schedule-mixin'
import NavigationHandlerMixin from '@/mixins/navigation-handler-mixin'
import { formTypes } from '../configuration/configuration-schedule'
import {
  AppointmentFlowEvent,
  BookingAndAppointmentDetailEvent,
  SpecialistProfileEvent
} from '@/types/events'
import { OriginEnums } from '@/types/origins'
import { userStatus } from '@/types/state'
import { code } from '@/constants/error'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleReason',
  props: {
    isEditing: Boolean,
    // summary 2.0
    isReschedule: {
      type: Boolean,
      default: false
    },
    specialist: Object,
    appointment: Object,
    formData: Object,
    reasonRequired: Boolean,
    virtual: Boolean
  },
  components: {
    PageContainer,
    TextareaField
  },
  mixins: [ScheduleMixin, NavigationHandlerMixin],
  data () {
    const translation = this.$translations['appointment-pages']['add-reason']
    const config = {
      title: this.isReschedule ? translation.reschedule : translation.consult,
      heading: this.isReschedule
        ? translation['reschedule-reason']
        : translation['consult-reason'],
      placeholder: translation['text-placeholder']
    }

    return {
      config,
      reason: null,
      submit: {
        text: this.$translations['appointment-pages'].buttons.continue,
        loading: false,
        disabled: true,
        callback: () => this.handlerSubmit()
      },
      back: {
        callback: () => {
          this.$emit('back', formTypes.date)
        }
      },
      isIncomplete: false,
      itemsState: {
        date: '',
        service: '',
        specialists: ''
      }
    }
  },
  created () {
    this.reasonInit()
  },
  methods: {
    reasonInit () {
      let valid = Boolean(
        this.formData.service &&
          this.formData.specialistService &&
          this.formData.starts_at
      )

      if (this.formData.service.first_session) {
        valid = Boolean(this.reason && valid)
      }

      this.submit.disabled = !valid

      AppointmentFlowEvent.Appointment_View_Reason({
        body: { user: this.$store.getters['auth/user'] }
      })
      if (this.isEditing) {
        if (this.isSecondAppointment) {
          this.itemsState = {
            service: 'default',
            specialists: 'default'
          }
        }
      }
      if (this.isReschedule) {
        this.itemsState = {
          service: 'disabled',
          specialists: 'disabled'
        }
      }
      if (this.formData.reason) {
        this.isIncomplete = false
        this.submit.disabled = false
      } else {
        this.isIncomplete = this.reasonRequired
        this.submit.disabled = this.reasonRequired
      }
    },
    setReason (reason) {
      this.reason = reason
      if (reason.length > 250) {
        this.reason = reason.slice(0, 250)
      }

      this.submit.disabled = this.reason === ''
    },
    handlerSubmit () {
      this.callSegmentEvents()
      this.submit.loading = true

      if (this.isReschedule) {
        this.appointmentReschedule()
      } else {
        this.$emit('submit', this.reason)
        // this.appointmentCreate()
      }
    },
    appointmentCreate () {
      const user = this.$store.getters['auth/user']

      Appointments.createAppointment({
        created_by: user.id,
        origin: OriginEnums.user,
        patient: user.id,
        reason: this.reason,
        specialist: this.specialist.id,
        specialist_service: this.formData.service.service.specialist_service_id,
        starts_at: this.formData.starts_at,
        virtual: this.formData.virtual
      })
        .then(async (response) => await this.onSuccess(response))
        .catch((error) => this.onError(error))
        .finally(() => {
          this.submit.disabled = true
          this.submit.loading = false
        })
    },
    appointmentReschedule () {
      const body = {
        starts_at: this.formData.starts_at,
        rescheduled_by: this.$store.getters['auth/user'].id,
        rescheduling_origin: OriginEnums.user,
        rescheduling_reason: this.reason,
        virtual: this.appointment.virtual
      }

      if (this.$route.query.origin === 'shared-account') {
        return SharedAccountAppointment.reschedule(
          body,
          this.sharedAccountId,
          this.member.id,
          this.appointment.id
        )
          .then(() => this.onSuccessReschedule())
          .catch((error) => this.onError(error))
          .finally(() => {
            this.submit.loading = false
          })
      }

      Appointments.update(this.appointment.id, body)
        .then(() => this.onSuccessReschedule())
        .catch((error) => this.onError(error))
        .finally(() => {
          this.submit.loading = false
        })
    },
    async onSuccessReschedule () {
      this.$toast({
        severity: 'success',
        text: this.$translations.success['appointment-rescheduled']
      })

      const user = this.$store.getters['auth/user']

      this.$segment.track(
        BookingAndAppointmentDetailEvent.select_reschduleappointment,
        {
          appointment_id: this.appointment.id,
          patient_name: `${user.first_name} ${user.last_name}`,
          specialist_name: `${this.appointment.specialist.user.first_name} ${this.appointment.specialist.user.last_name}`,
          starts_at: this.starts_at,
          day: this.$moment(this.starts_at).format('DD/MM/YYYY'),
          time: this.$moment(this.starts_at).format('HH:mm'),
          service_name: this.appointment.service.service_type.name,
          status: this.appointment.status,
          created_at: this.appointment.created_at
        }
      )

      if (this.$route.query.back) {
        this.$router.replace(this.$route.query.back)
      } else {
        this.$router.replace({
          name: 'AppointmentDetails',
          params: {
            id: this.appointment.id
          },
          query: {
            back: 'Agenda'
          }
        })
      }
    },
    async onSuccess (response) {
      const user = this.$store.getters['auth/user']

      if (user.status !== userStatus.active) {
        let status

        if (
          this.formData.type === 'FIRST_CONTACT' &&
          (user.status === userStatus.waiting_for_orientation ||
            user.status === userStatus.account_created)
        ) {
          status = userStatus.waiting_for_first_contact
        } else {
          status = userStatus.active
        }

        await this.$store.dispatch('auth/fetchUser', {
          ...user,
          status
        })
      }

      BookingAndAppointmentDetailEvent.ScheduleAppointmentSuccessful({
        user: this.$store.getters['auth/user'],
        bookappointment: {
          appointment_id: response.id,
          patient_name: response.patient_name,
          specialist_name: response.specialist_name,
          starts_at: response.starts_at,
          day: this.$moment(response.starts_at).format('DD/MM/YYYY'),
          time: this.$moment(response.starts_at).format('HH:mm'),
          service_name: response.service_name,
          status: response.status,
          created_at: response.created_at
        }
      })

      await this.$router.push({
        name: 'CheckOut',
        params: {
          id: response.id,
          type: 'APPOINTMENT'
        },
        query: {
          showSuccess: this.formData.price === 0,
          specialistId: this.specialist.id,
          origin:
            this.$route.query.origin === 'specialist-card'
              ? this.$route.query.origin
              : null
        }
      })
    },
    onError (error) {
      if (error.error === code.S1020) {
        this.$globalEvent.$emit('modal/change-date', {
          showDialog: {
            ChangeDate: {
              open: true,
              close: false,
              callback: () => this.redirect(formTypes.date)
            }
          }
        })

        this.submit.disabled = true
        this.itemsState.date = 'error'
      }

      BookingAndAppointmentDetailEvent.ScheduleAppointmentFailed({
        user: this.$store.getters['auth/user'],
        error: error.message
      })
    },
    callSegmentEvents () {
      AppointmentFlowEvent.Appointment_Save_Reason({
        body: {
          user: this.$store.getters['auth/user'],
          reason: this.reason
        }
      })
      SpecialistProfileEvent.clickSchedule({
        user: this.$store.getters['auth/user'],
        specialist: {
          firstName: this.specialist.first_name,
          id: this.specialist.id,
          lastName: this.specialist.last_name,
          profilePicture: this.specialist.profile_picture,
          ...this.formData
        }
      })
    },
    /**
     * Mixins overrides
     */
    handleBackButton () {
      this.back.callback()
    }
  },
  computed: {
    ...mapGetters({
      member: 'sharedAccount/member',
      sharedAccountId: 'sharedAccount/sharedAccountId'
    })
  }
}
</script>
